// // //Blog.js

// // import React from 'react';
// // import './Blog.css';

// // const Blog = () => {
// //   const tumblrUrl = 'https://jackandtheripper.tumblr.com/'; 
  
// //   return (
// //     <div className="blog-container">
// //       <iframe
// //         src={tumblrUrl}
// //         title="Jack and the Ripper Tumblr Feed"
// //         width="100%"
// //         height="400px"
// //         frameBorder="0"
// //         style={{ overflowY: 'scroll', 
// //                 scrollbarWidth: 'thin', 
// //                 scrollbarColor: '#ccc transparent' 
// //               }}
// //       />
// //     </div>
// //   );
// // };

// // export default Blog;



import { useState, useEffect } from 'react';
import './Blog.css';

const Blog = () => {
  const tumblrUrl = 'https://jackandtheripper.tumblr.com/';
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="blog-container">
      {isMobile ? (
        <div className="mobile-blog-view">
          <p>Please visit our blog on Tumblr:</p>
          <a 
            href={tumblrUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="tumblr-link"
          >
            Open Blog
          </a>
        </div>
      ) : (
        <iframe
          src={tumblrUrl}
          title="Jack and the Ripper Tumblr Feed"
          className="blog-iframe"
          frameBorder="0"
          style={{ overflowY: 'scroll', scrollbarWidth: 'thin', scrollbarColor: '#ccc transparent' }}
        />
      )}
    </div>
  );
};

export default Blog;





// import React from 'react';
// import './Blog.css';

// const Blog = () => {
//   const tumblrUrl = 'https://jackandtheripper.tumblr.com/'; 

//   return (
//     <div className="blog-container">
//       <iframe
//         src={tumblrUrl}
//         title="Jack and the Ripper Tumblr Feed"
//         className="blog-iframe"
//         frameBorder="0"
//         style={{ overflowY: 'scroll', scrollbarWidth: 'thin', scrollbarColor: '#ccc transparent' }}
//       />
//     </div>
//   );
// };

// export default Blog;







// // import React from 'react';

// // const Blog = () => {
// //   const tumblrUrl = 'https://jackandtheripper.tumblr.com/'; // Replace with your actual Tumblr URL
// //   return (
// //     <div className="blog-container">
// //       {/* <h2>Blog Posts (Limited View)</h2> */}
// //       <iframe
// //         src={tumblrUrl}
// //         title="Jack and the Ripper Tumblr Feed"
// //         width="100%"
// //         height="400px"
// //         frameBorder="0"
// //         style={{ overflowY: 'scroll', /* Enable scrollbar */
// //                 scrollbarWidth: 'thin', /* Optional custom scrollbar width */
// //                 scrollbarColor: '#ccc transparent' /* Optional custom scrollbar color */
// //               }}
// //       />
// //       {/* <p>
// //         **Important:** This iframe displays a limited portion of the blog. To view full posts and scroll through them, please visit{' '}
// //         <a href={tumblrUrl} target="_blank" rel="noreferrer noopener">
// //           Jack and the Ripper Tumblr
// //         </a>
// //       </p> */}
// //     </div>
// //   );
// // };

// // export default Blog;